import JSBeautifyComponent from "@jordibosch20/software_tools_package/dist/pages/JSBeautify.component.js"
import styles from "./App.module.css";
import { getJSBeautifyString } from "@jordibosch20/software_tools_package/dist/utils/js-beautify";
import { useAnalytics } from './useAnalytics';

function App() {
  useAnalytics();
  return (
    <div className={styles.AppWrapper}>
      <div className={styles.App}>
        <JSBeautifyComponent title={"JSON beautify"} f={getJSBeautifyString}></JSBeautifyComponent>
      </div>
      <div className={styles.text}>
        <h3>Introduction</h3>
        <p>When working with JSON, especially in the context of development and debugging, readability is crucial. Compilers, APIs, and data serialization processes often condense JSON into a minified format to save space and improve transmission speed. However, this compact form can be a significant hurdle when attempting to debug or understand the data structure. A well-formatted JSON is indispensable for these tasks.</p>
        <h3>Discover Our Tool: The Instant JSON Beautifier</h3>
        <p>Imagine the ease of converting a dense, minified JSON string into a perfectly formatted and easily navigable document with a single click. That's the essence of what our JSON beautifier delivers. Gone are the days of poring over endless lines of compressed data or puzzling out the structure of your JSON. Our tool takes on the burden, ensuring your JSON is not only readable but also optimized for better handling and analysis.</p>
        <h4>Key Features:</h4>
        <ul>
        <li><strong>One-Click Transformation:</strong> Paste your JSON, click beautify, and instantly enjoy clear, understandable data.</li>
        <li><strong>Customization:</strong> Adapt the output to match your coding practices, from spacing to hierarchy levels.</li>
        </ul>
        <h3>How to Use the Instant JSON Beautifier</h3>
        <ol>
        <li><strong>Copy and Paste:</strong> Initiate the process by copying your compact or messy JSON data into the beautifier's input field.</li>
        <li><strong>Customize Settings:</strong> Select your formatting preferences, such as indentation size or structure spacing.</li>
        <li><strong>Beautify:</strong> With a mere click, transform your data into a neatly organized, optimized format.</li>
        <li><strong>Review and Apply:</strong> Examine the output for any further adjustments, then seamlessly incorporate it back into your project or analysis.</li>
        </ol>
        <h3>Can I Paste Confidential Data?</h3>
        <div>
        <div><span>Rest assured, your pasted information remains secure within your browser. Our tool is purely client-side, meaning there's no data transmission to servers, so your data stays right where it is&mdash;on your device.</span></div>
        </div>
        <p><strong>Is the Beautifier Suited for Large JSON Files?</strong><br />A: Absolutely! Our tool is designed to efficiently process JSON data of any size, from small snippets to extensive datasets, ensuring quick and effective formatting and optimization.</p>
        <div className={styles.links}>
          <h3>Other similar resoures that could be helpful</h3>

          <li><a href="https://check-json.com">JSON format checker</a></li>
          <li><a href="https://check-xml.com">XML Checker</a></li>
          <li><a href="https://check-yaml.com">YAML Checker</a></li>
          <li><a href="https://formatter-json.com">JSON formatter</a></li>
          <li><a href="https://add-delimiters.com">Add delimiters to sentences</a></li>
          <li><a href="https://convert-base.com">Convert base</a></li>
          <li><a href="https://convert-binary.com">Convert text/file to binary</a></li>
          <li><a href="https://convert-hexadecimal.com">Convert text/file to hexadecimal</a></li>
          <li><a href="https://convert-base64.com">Convert text/file to base64</a></li>
          <li><a href="https://css-beautify.com">CSS beautifier</a></li>
          <li><a href="https://html-beautify.com">HTML beautifier</a></li>
          <li><a href="https://javascript-beautify.com">Javascript beautifier</a></li>
          <li><a href="https://xml-beautify.com">XML beautifier</a></li>
          <li><a href="https://extract-email.com">Email extractor</a></li>
          <li><a href="https://extract-phone.com">Phone extractor</a></li>
          <li><a href="https://extract-url.com">URL extractor</a></li>
          <li><a href="https://json-2-csv.com">JSON to CSV online converter</a></li>
          <li><a href="https://json-to-yaml.com">JSON to YAML online converter</a></li>
          <li><a href="https://software-kit.com">Software tools</a></li>
          <li><a href="https://svg2css.com">SVG to CSS background </a></li>
          <li><a href="https://trim-text.com">Text trimmer</a></li>
          <li><a href="https://url-coder.com">URL encoder/decoder</a></li>
          <li><a href="https://yaml2json.com">YAML to JSON</a></li>
          <li><a href="https://csv-to-json.com">CSV to JSON</a></li>
          <li><a href="https://sort-text.com">Sort text</a></li>
          <li><a href="https://convert-casing.com">Convert casing</a></li>
          <br></br>
          <br></br>
          <br></br>
          <span>In case you have any <b>suggerence or found any bug</b> in our tools, please, feel free to email us at statisticskingdom @ gmail.com (without the spaces) and we will take care of it.</span>
        </div>
      </div>
    </div>
  );
}

export default App;